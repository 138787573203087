<template>
  <div>
    <trac-loading v-if="loading" />
    <div class="mb-2 mx-12 mt-12">
      <div
        @click="$router.go(-1)"
        class="mb-1 cursor-pointer text-sm font-light"
      >
        &lt; Back
      </div>
      <h1 class="font-bold">Sync Store Settings</h1>
    </div>
    <div class="p-6 m-12 small-shadow rounded bg-white">
      <div class="border py-4 border-gray-300 rounded-md">
        <div class="flex mx-4 items-center">
          <img class="w-6 h-6 mr-3" src="@/assets/images/noona-home.png" alt="">
          <h1 class="font-medium flex-1 text-xs">
            Link Noona Locations to Traction Stores
          </h1>
          <trac-button @button-clicked="checkStatus()" variant="small" class="px-4 flex-initial">
            <div class="leading-7 uppercase font-bold">Sync Noona Stores</div>
          </trac-button>
        </div>
        <hr class="h-px mt-4 mb-2 bg-gray-200 border-0 dark:bg-gray-700" />

        <div class="mx-4 mt-4">
          <div class="flex">
            <h1 class="text-xs font-bold flex-1">Noona Locations</h1>
            <h1 class="text-xs font-bold flex-1">Link Store</h1>
          </div>
          <div
            v-for="(record, index) in noonaBusinesses"
            :key="index"
            class="flex my-4 items-center gap-x-2"
          >
            <trac-input
              :value="record.noona_business.name || 'N/A'"
              placeholder="Store Name"
              :disabled="true"
              class="flex-1"
            />
            <div>--&gt;</div>
            <trac-dropdown
              @onselect="prepAssociation($event, index, record._id)"
              :menuItems="storeList"
              :title="record.store_name || 'Select a Store'"
              class="flex-1"
            ></trac-dropdown>
          </div>
        </div>
        <div class="mx-4">
          <div v-if="!canLinkStores">
            <div
              class="text-xs font-bold inline p-1 border text-red-600 border-red-200 rounded-md"
            >
              Assign at least one store to a Noona Location
            </div>
          </div>
          <trac-button
            :disabled="!canLinkStores"
            @button-clicked="doLinkStores()"
            class="w-auto my-4 uppercase"
          >
            Save
          </trac-button>
        </div>

        <!-- <div class="p-8 mx-4 border grid grid-cols-2 gap-x-6 border-gray-400 rounded-md mt-6">
          <h1 class="font-bold col-span-2 mb-6">Link Noona location to Traction stores</h1>
          <div class="col-span-1">
            <span class="text-xs text-gray-600 inline-block mb-1">Select Noona Location</span>
            <trac-dropdown class="w-full"></trac-dropdown>
          </div>
          <div class="col-span-1">
            <span class="text-xs text-gray-600 inline-block mb-1">Link Store</span>
            <trac-dropdown class="w-full"></trac-dropdown>
          </div>
          <div class="text-blue-700 text-xs my-6 font-bold">Link another store</div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { GET_USER_BUSINESS_ID, GET_USER_DATA } from "../../browser-db-config/localStorage";
import { eventBus } from "../../../src/eventBus";
export default {
  data() {
    return {
      copied: false,
      noonaBusinesses: [],
      loading: false,
      stores: (GET_USER_DATA() || {}).stores || [],
      associations: {},
      business_id: GET_USER_BUSINESS_ID()
    };
  },
  created() {
    this.noonaBusinesses = this.$route.params.fetchedNoonaData || [];
    if (this.noonaBusinesses.length < 1) {
      // load from endpoint
      // this.loading = true;
      this.checkStatus();
      // this.$store.dispatch("NOONA_SYNC").then((data) => {
      //   console.log('Sync Data: ', data)
      // })
    }
  },
  computed: {
    canLinkStores() {
      return Object.keys(this.associations).length > 0;
    },
    storeList() {
      return this.stores.map((record) => {
        return { name: record.name, value: record.id };
      });
    },
    associationsPayload() {
      const indexKeys = Object.keys(this.associations);
      const data = indexKeys.map((record) => {
        return this.associations[record];
      });
      return {
        business_id: this.business_id,
        data,
      };
    },
  },
  methods: {
    prepAssociation(data, index, noona_business_id) {
      console.log("From dropdown", data);
      console.log("index", index);
      const structure = {
        store_id: data.value,
        store_name: data.name,
        noona_business_id: noona_business_id,
      };
      this.$set(this.associations, index, structure);
    },
    doLinkStores() {
      this.loading = true;
      this.$store
        .dispatch("NOONA_LINK", this.associationsPayload)
        .then((data) => {
          if (data.status) {
            eventBus.$emit("trac-alert", {
              message: data.message,
            });
            this.loading = false;
            this.$router.push({name: 'BookingSettings'})
          } else {
            eventBus.$emit("trac-alert", {
              message: data.message,
            });
            this.loading = false;
          }
        });
    },
    checkStatus() {
      this.loading = true
      this.$store
        .dispatch("NOONA_SYNC")
        .then((data) => {
          console.log("data resolved: ", data);
          if (data.status) {
            this.noonaBusinesses = data.data || [];
            this.loading = false;
            // this.checkToProceed();
          } else {
            this.$router.push({ name: "Noona" });
          }
        })
        .catch(() => {
          this.$router.push({ name: "Noona" });
          this.loading = false
          this.message = "An error occured. Please try again"
        });
    },
  },
};
</script>

<style lang="postcss" scoped>
.trim-box {
  background: #f6fcff;
  border: 0.3px dashed #253b95;
  border-radius: 5px;
}
</style>